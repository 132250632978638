import authIsLoggedIn from './subscriptions/auth/is-logged-in.graphql';
import authSsoOnlyDomains from './subscriptions/auth/sso-only-domains.graphql';
import authIsDemoSite from './subscriptions/auth/is-demo-site.graphql';

import contextDashboard from './subscriptions/context/dashboard.graphql';

import user from './subscriptions/accounts/user.graphql';
import users from './subscriptions/accounts/users.graphql';
import userSettings from './subscriptions/accounts/user-settings.graphql';

import importExports from './subscriptions/import-exports.graphql';

import dashboardReportsTemplateMeta from './subscriptions/dashboard/dashboard-reports-template-meta.graphql';

import priorityAreasPlanning from './subscriptions/area/priority-area/priority-areas-planning.graphql';
import priorityAreaPlanning from './subscriptions/area/priority-area/priority-area-planning.graphql';
import priorityAreaPlanningDelete from './subscriptions/area/priority-area/priority-area-planning-delete.graphql';
import priorityAreaPlanningBreadcrumbs from './subscriptions/area/priority-area/priority-area-planning-breadcrumbs.graphql';
import priorityAreaTracker from './subscriptions/area/priority-area/priority-area-tracker.graphql';
import priorityAreasHome from './subscriptions/area/priority-area/priority-areas-home.graphql';

import interventionsPlanning from './subscriptions/area/intervention/interventions-planning.graphql';
import interventionPlanning from './subscriptions/area/intervention/intervention-planning.graphql';
import intervention from './subscriptions/area/intervention/intervention.graphql';
import interventionTracker from './subscriptions/area/intervention/intervention-tracker.graphql';
import interventions from './subscriptions/area/intervention/interventions.graphql';

import areaPermissions from './subscriptions/area/area-permissions.graphql';

import trackerLanding from './subscriptions/tracker-landing.graphql';
import activityTracker from './subscriptions/activity-tracker.graphql';

import content from './subscriptions/content/content.graphql';
import myContent from './subscriptions/content/my-content.graphql';

import contentBlockers from './subscriptions/content/content-blockers.graphql';

import milestonesPlanning from './subscriptions/area/milestones-planning.graphql';

import contentGroup from './subscriptions/content-group.graphql';
import contentGroups from './subscriptions/content-groups.graphql';

import reports from './subscriptions/report/reports.graphql';
import report from './subscriptions/report/report.graphql';
import reportData from './subscriptions/report/report-data.graphql';
import reportDataPriorityArea from './subscriptions/report/report-data-priority-area.graphql';
import reportDataIntervention from './subscriptions/report/report-data-intervention.graphql';

import areaLevels from './subscriptions/area/area-levels.graphql';

import targetUnits from './subscriptions/target-units.graphql';

import classifications from './subscriptions/classifications.graphql';

export const AUTH_IS_LOGGED_IN_SUBSCRIPTION = authIsLoggedIn;
export const AUTH_SSO_ONLY_DOMAINS_SUBSCRIPTION = authSsoOnlyDomains;
export const AUTH_IS_DEMO_SITE_SUBSCRIPTION = authIsDemoSite;

export const CONTEXT_DASHBOARD_SUBSCRIPTION = contextDashboard;

export const CONTEXT_USER_SUBSCRIPTION = user;
export const CONTEXT_USERS_SUBSCRIPTION = users;
export const USER_SETTINGS_SUBSCRIPTION = userSettings;

export const IMPORT_EXPORTS_SUBSCRIPTION = importExports;

export const AREA_PERMISSIONS_SUBSCRIPTION = areaPermissions;

export const PRIORITY_AREAS_PLANNING_SUBSCRIPTION = priorityAreasPlanning;
export const PRIORITY_AREA_PLANNING_SUBSCRIPTION = priorityAreaPlanning;
export const PRIORITY_AREA_PLANNING_DELETE_SUBSCRIPTION =
  priorityAreaPlanningDelete;
export const PRIORITY_AREA_PLANNING_BREADCRUMBS_SUBSCRIPTION =
  priorityAreaPlanningBreadcrumbs;
export const PRIORITY_AREA_TRACKER_SUBSCRIPTION = priorityAreaTracker;
export const PRIORITY_AREAS_HOME_SUBSCRIPTION = priorityAreasHome;

export const INTERVENTIONS_PLANNING_SUBSCRIPTION = interventionsPlanning;
export const INTERVENTION_PLANNING_SUBSCRIPTION = interventionPlanning;
export const INTERVENTION_SUBSCRIPTION = intervention;
export const INTERVENTION_TRACKER_SUBSCRIPTION = interventionTracker;
export const INTERVENTIONS_SUBSCRIPTION = interventions;

export const TRACKER_LANDING_SUBSCRIPTION = trackerLanding;
export const ACTIVITY_TRACKER_SUBSCRIPTION = activityTracker;

export const CONTENT_SUBSCRIPTION = content;
export const MY_CONTENT_SUBSCRIPTION = myContent;

export const CONTENT_BLOCKERS_SUBSCRIPTION = contentBlockers;

export const MILESTONES_PLANNING_SUBSCRIPTION = milestonesPlanning;

export const CONTENT_GROUP_SUBSCRIPTION = contentGroup;
export const CONTENT_GROUPS_SUBSCRIPTION = contentGroups;

export const REPORTS_SUBSCRIPTION = reports;
export const REPORT_SUBSCRIPTION = report;
export const REPORT_DATA_SUBSCRIPTION = reportData;
export const REPORT_DATA_PRIORITY_AREA_SUBSCRIPTION = reportDataPriorityArea;
export const REPORT_DATA_INTERVENTION_SUBSCRIPTION = reportDataIntervention;

export const AREA_LEVELS_SUBSCRIPTION = areaLevels;

export const TARGET_UNITS_SUBSCRIPTION = targetUnits;

export const DASHBOARD_REPORTS_TEMPLATE_META_SUBSCRIPTION =
  dashboardReportsTemplateMeta;

export const CLASSIFICATIONS_SUBSCRIPTION = classifications;
