import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ScreenAuthLogin } from '../../_screens/auth/login';
import { ScreenAuthPasswordForgot } from '../../_screens/auth/password-forgot';
import { ScreenAuthPasswordReset } from '../../_screens/auth/password-reset';
import { TwoFactorVerification } from '../../_screens/auth/two-factor';

export function AuthRouter() {
  const { t } = useTranslation();
  const location = useLocation();

  const { pathname, search } = location;

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
    >
      {/* Favicons */}
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${process.env.PUBLIC_URL}/assets/img/favicons/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${process.env.PUBLIC_URL}/assets/img/favicons/favicon-16x16.png`}
        />
        <link
          rel="icon"
          href={`${process.env.PUBLIC_URL}/assets/img/favicons/favicon.ico`}
        />
        <title>{t('Digital Delivery Dashboard')}</title>
      </Helmet>

      <Switch>
        <Route exact path="/auth/two-factor-auth">
          <TwoFactorVerification />
        </Route>
        <Route exact path="/auth/forgot">
          <ScreenAuthPasswordForgot />
        </Route>
        <Route path="/accounts/reset">
          <ScreenAuthPasswordReset />
        </Route>
        <Route exact path="/">
          <ScreenAuthLogin />
        </Route>
        <Route path="*">
          <Redirect to={`/?next=${pathname}${search.length ? search : ''}`} />
        </Route>
      </Switch>
    </GoogleReCaptchaProvider>
  );
}
