import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export function AuthLoginNext({ loading }: any) {
  const { t } = useTranslation();

  return (
    <Box sx={{ textAlign: 'right' }}>
      <LoadingButton
        variant="contained"
        disableElevation
        type="submit"
        loading={loading}
        endIcon={<ArrowForwardIcon />}
      >
        {t('Next')}
      </LoadingButton>
    </Box>
  );
}
