import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { Link as RouterLink } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { ReactError } from '../../_lib/react-error';
import { TwoFactorVerifyCode } from '../../components/my-account/details/two-factor/verify-code';

export function TwoFactorVerification() {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);

  // ---------------------------------------------------------------------------------------------------------------------

  return (
    <ErrorBoundary FallbackComponent={ReactError}>
      <Container maxWidth="xs" sx={{ py: 14 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" color="grey.800" sx={{ mb: 4 }}>
            {t('Digital Delivery Dashboard')}
          </Typography>

          <Avatar sx={{ bgcolor: 'primary.main', my: 1, mx: 'auto' }}>
            <PhoneIphoneOutlinedIcon />
          </Avatar>
          <Typography variant="h6" color="grey.800" sx={{ mb: 2 }}>
            {t('Two Factor Authentication')}
          </Typography>
        </Box>

        <Stack spacing={2}>
          <Typography textAlign="left">
            {t(
              'Your account is enabled for two factor authentication code. Kindly provide the code from your authenticator app to proceed to view your dashboard.'
            )}
          </Typography>

          <TwoFactorVerifyCode
            includeBackupCodesOption
            spacing={2}
            loading={loading}
            setLoading={setLoading}
            onSuccess={() => {
              // check if next is set
              const next = new URLSearchParams(window.location.search).get(
                'next'
              );

              // next is not here, open it in a new tab
              if (next && !next.startsWith(window.location.origin)) {
                window.open(next, '_self');
              }

              // next is here, open it in the same tab
              if (next && next.startsWith(window.location.origin)) {
                window.location.href = next;
              } else {
                window.location.href = '/';
              }
            }}
          />

          <Box>
            <Button
              component={RouterLink}
              to={`/${window.location.search}`}
              sx={{ textTransform: 'none' }}
              startIcon={<KeyboardBackspaceIcon />}
              disabled={loading}
            >
              {t('Login Page')}
            </Button>
          </Box>

          <Typography>
            {t(
              'Open the two-factor authenticator (TOTP) app on your mobile device to view your authentication code.'
            )}
          </Typography>
        </Stack>
      </Container>
    </ErrorBoundary>
  );
}
