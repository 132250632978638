import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useSummary } from '../../_utils/hooks/queries/use-summary';

export function DashboardSummaryCounts({
  filterPriorityAreaIds,
  filterInterventionIds,
}: any) {
  const { t } = useTranslation();

  const {
    dashboard: {
      priorityAreaName,
      interventionName,
      homeOverviewInterventionsCountDistinct,
      areaLevels,
      enableAreaLevels,
    },
  } = useDashboardContext();

  const areaLevelNames: string = React.useMemo(
    () =>
      areaLevels
        ? areaLevels.map((areaLevel: any) => areaLevel.name).join(', ')
        : '',
    [areaLevels]
  );

  const variables = React.useMemo(
    () => ({
      filterPriorityAreaIds,
      filterInterventionIds,
    }),
    [filterPriorityAreaIds, filterInterventionIds]
  );

  const { data, loading, error } = useSummary('counts', variables, 'dashboard');

  // ----------------------------------------------------------------------------------------

  if (error) return null;

  if (loading || !data) return <Skeleton variant="rectangular" height={40} />;

  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <Typography
        variant="subtitle1"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tooltip title={!enableAreaLevels ? priorityAreaName : areaLevelNames}>
          <span
            style={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '150px',
              fontWeight: 600,
            }}
          >
            {!enableAreaLevels ? priorityAreaName : areaLevelNames}
          </span>
        </Tooltip>
        : {data.dashboard?.summary.priorityAreasCount || '-'}
        <Typography component="span" sx={{ mx: 1 }}>
          •
        </Typography>
        <Typography
          component="span"
          sx={{
            fontWeight: 600,
            mr: 0.5,
          }}
        >
          {interventionName}:{' '}
        </Typography>
        {data.dashboard.summary.interventionsCount || '-'}
        {homeOverviewInterventionsCountDistinct && (
          <>
            {' '}
            <Tooltip title={`${t('Distinct Count by Name')}`} arrow>
              <Chip label={`${t('Distinct')}`} size="small" sx={{ ml: 1 }} />
            </Tooltip>
          </>
        )}
      </Typography>
    </Card>
  );
}
