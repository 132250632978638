import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';

export function AppbarAvatarAlertsDemo() {
  const { t } = useTranslation();

  if (!process.env.REACT_APP_IS_DEMO_SITE) return null;

  return (
    <Alert severity="info">
      {t('This is a demo site. The data is deleted every hour.')}
    </Alert>
  );
}
