import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';

import { ReactErrorComponent } from '../../../_lib/react-error';
import { SubscribeToMoreComponent } from '../../subscribe-to-more';

import { USER_SETTINGS_QUERY } from '../../../_lib/graphql/queries';
import { USER_SETTINGS_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

import { MyAccountPreferencesDetail } from './detail';
import { MyAccountPreferencesNoDashboards } from './no-dashboards';

export function MyAccountPreferences() {
  const { t } = useTranslation();

  const { data, loading, error, refetch, subscribeToMore } =
    useQuery(USER_SETTINGS_QUERY);

  // --------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------------

  // refetch if needed
  React.useEffect(() => {
    if (!data && !loading && !error) refetch();
  }, [data, loading, error, refetch]);

  // --------------------------------------------------------------------------------------------------------------------------

  return (
    <Card sx={{ mb: 6, px: 3, pt: 3, pb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t('My Dashboards')}
      </Typography>

      <Stack spacing={3} divider={<Divider />}>
        {/* dashboards */}
        {data?.user?.userSettings?.map((setting: any) => (
          <MyAccountPreferencesDetail key={setting.id} setting={setting} />
        ))}

        {/* no dashboards found */}
        {!data?.user?.userSettings?.length && !loading && (
          <MyAccountPreferencesNoDashboards />
        )}

        {/* loading */}
        {loading &&
          [1, 2, 3].map((i) => (
            <Skeleton key={i} variant="rectangular" height={100} />
          ))}
      </Stack>

      {/* error */}
      {error && <ReactErrorComponent error={error} />}

      {/* subscription */}
      {!!data && (
        <SubscribeToMoreComponent
          subscribeToMore={subscribeToMore}
          document={USER_SETTINGS_SUBSCRIPTION}
        />
      )}
    </Card>
  );
}
