import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import LoadingButton from '@mui/lab/LoadingButton';

export function AuthLoginPassword({
  loading,
  authError,
  formError,
  demoPassword,
}: any) {
  const { t } = useTranslation();

  return (
    <>
      {/* password */}
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label={t('Password')}
        type="password"
        id="password"
        autoComplete="current-password"
        disabled={loading}
        error={
          (!!formError && !!authError.message?.length) ||
          (!!formError && !!formError.password)
        }
        defaultValue={
          process.env.REACT_APP_IS_DEMO_SITE ? demoPassword : undefined
        }
        key={process.env.REACT_APP_IS_DEMO_SITE ? demoPassword : undefined}
      />

      {/* remember me */}
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label={t('Remember me')}
        disabled={loading}
      />

      {/* sign in button */}
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={loading}
        disableElevation
      >
        {t('Sign In')}
      </LoadingButton>

      {/* forgot password */}
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to="/auth/forgot" variant="body2">
            {t('Forgot password?')}
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
