import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useAuth } from '../../_utils/hooks/use-auth';
import { logError } from '../../_lib/error';
import { getApiURL } from '../../_utils/general-utils';
import { SubscribeToMoreComponent } from '../../components/subscribe-to-more';

import {
  AUTH_IS_DEMO_SITE_QUERY,
  AUTH_SSO_ONLY_DOMAINS_QUERY,
} from '../../_lib/graphql/queries';
import {
  AUTH_IS_DEMO_SITE_SUBSCRIPTION,
  AUTH_SSO_ONLY_DOMAINS_SUBSCRIPTION,
} from '../../_lib/graphql/subscriptions';

import { AuthScreenLayout } from '../../auth/_layout';
import { AuthLoginPassword } from '../../auth/login/password';
import { AuthLoginNext } from '../../auth/login/next';
import { AuthLoginErrorMessage } from '../../auth/login/error-message';

interface AuthError {
  failedAuth: boolean;
  lockedAuth: boolean;
  netError: boolean;
  other: boolean;
  message?: string | null;
}

const initialAuthErrorState: AuthError = {
  failedAuth: false,
  lockedAuth: false,
  netError: false,
  other: false,
  message: '',
};
const initialFormErrorState = {
  email: null,
  password: null,
} as any;

export function ScreenAuthLogin() {
  const { t } = useTranslation();
  const { signIn } = useAuth();
  const history = useHistory();

  const AUTH_ERR_MSG = React.useMemo(
    () => ({
      WRONG_CREDENTIALS: `${t(
        `Incorrect email or password. Please try again`
      )} ${t('or')} <a href="/auth/forgot">${t('reset your password')}</a>.`,
      LOCKED_OUT: t(
        `Too many failed attempts, account locked out for 10 minutes.`
      ),
      OTHER_ERROR: `${t(`Something went wrong.`)} ${t('Please try again.')}`,
      NET_ERROR: t(
        `Could not sign you in. Ensure you are connected to the internet.`
      ),
      TWO_FACTOR_REQUIRED: t(`Two-factor authentication required.`),
      NOT_IN_SSO_DOMAIN: `${t(
        `You are not allowed to sign in with that account.`
      )} ${t('Please try again.')}`,
    }),
    [t]
  );

  const [loading, setLoading] = React.useState(true);
  const [authError, setAuthError] = React.useState<AuthError>(
    initialAuthErrorState
  );
  const [formError, setFormError] = React.useState(initialFormErrorState);
  const [demoEmail, setDemoEmail] = React.useState(
    'demo.su@digitaldashboard.cc'
  );
  const [demoPassword, setDemoPassword] = React.useState('tbidashboard');

  const [showNext, setShowNext] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showLoginWithMs, setShowLoginWithMs] = React.useState(false);

  const [email, setEmail] = React.useState('');

  // Demo credentials
  const {
    data: dataIsDemoSiteQuery,
    error: errorAuthIsDemoSite,
    subscribeToMore: subscribeToMoreAuthIsDemosSite,
  } = useQuery(AUTH_IS_DEMO_SITE_QUERY);

  // sso only domains
  const {
    data: dataSsoOnlyDomains,
    loading: loadingSsoOnlyDomains,
    error: errorAuthSsoOnlyDomains,
    subscribeToMore: subscribeToSsoOnlyDomains,
  } = useQuery(AUTH_SSO_ONLY_DOMAINS_QUERY);

  // ----------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------

  // Validate the data
  const valid = (data: any) => {
    const newErrors = {} as any;
    if (!data.get('password')) {
      newErrors.password = true;
      setFormError(newErrors);
    }
    return !newErrors.password;
  };

  // Handle login
  const handleLogin = (data: any) => {
    setFormError(initialFormErrorState);
    setLoading(true);

    signIn(email, data.get('password'))
      .then((r: any) => {
        if (!r?.data?.authLogin?.success) {
          setAuthError((old) => ({
            ...old,
            failedAuth: true,
            message: AUTH_ERR_MSG.WRONG_CREDENTIALS,
          }));
          setLoading(false);
        } else if (r?.data?.authLogin?.user?.isTwoFactorEnabled) {
          // navigate to OTP page /auth/two-factor-auth/
          history.push(`/auth/two-factor-auth/${window.location.search}`);
        } else {
          // check if next is set
          const next = new URLSearchParams(window.location.search).get('next');

          // what next
          if (next) {
            if (!next.startsWith(window.location.origin)) {
              window.open(next, '_self');
            } else {
              window.location.href = next;
            }
          } else {
            window.location.href = '/';
          }
        }
      })
      .catch((err: any) => {
        const { message, networkError } = err;

        const result = `${networkError?.result} ${message}`;

        if (result) {
          if (result.toLowerCase().includes('two-factor')) {
            // two factor
            history.push(`/auth/two-factor-auth/${window.location.search}`);
            return;
          }
          if (
            result.toLowerCase().includes('network') ||
            result.toLowerCase().includes('failed to fetch')
          ) {
            // network
            setAuthError({
              ...initialAuthErrorState,
              netError: true,
              message: AUTH_ERR_MSG.NET_ERROR,
            });
          } else if (result.toLowerCase().includes('account lo')) {
            // locked out
            setAuthError({
              ...initialAuthErrorState,
              lockedAuth: true,
              message: AUTH_ERR_MSG.LOCKED_OUT,
            });
          } else {
            // other
            setAuthError({
              ...initialAuthErrorState,
              other: true,
              message: `${AUTH_ERR_MSG.OTHER_ERROR} ${result}`,
            });
          }
        } else {
          setAuthError({
            ...initialAuthErrorState,
            other: true,
            message: err.toString(),
          });
        }
        logError(err);
        setLoading(false);
      });
  };

  // Handle form submit
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setAuthError(initialAuthErrorState);
    setFormError(initialFormErrorState);

    const data = new FormData(event.currentTarget);
    const newEmail = data.get('email')?.toString();

    // if showNext, show password
    if (showNext) {
      if (!newEmail) {
        setFormError({ email: true });
        return;
      }
      setEmail(newEmail);
      setShowNext(false);
      if (
        dataSsoOnlyDomains?.authSsoOnlyDomains?.filter((d: any) =>
          newEmail.endsWith(`@${d}`)
        ).length
      ) {
        setShowLoginWithMs(true);
      } else {
        setShowPassword(true);
      }
      return;
    }

    // validate
    if (!valid(data)) {
      return;
    }

    // if showPassword, handle login
    handleLogin(data);
  };

  // ----------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (dataIsDemoSiteQuery?.authIsDemoSite) {
      setLoading(false);
      setDemoEmail(dataIsDemoSiteQuery.authIsDemoSite.email);
      setDemoPassword(dataIsDemoSiteQuery.authIsDemoSite.password);
    }
  }, [dataIsDemoSiteQuery]);

  React.useEffect(() => {
    if (errorAuthIsDemoSite) {
      logError(errorAuthIsDemoSite);
    }
  }, [errorAuthIsDemoSite]);

  // track errors passed back from the server
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('error') === 'not_in_sso_domains') {
      setAuthError((old) => ({
        ...old,
        failedAuth: true,
        message: AUTH_ERR_MSG.NOT_IN_SSO_DOMAIN,
      }));
    }
  }, [AUTH_ERR_MSG.NOT_IN_SSO_DOMAIN]);

  // ----------------------------------------------------------------------------------------

  return (
    <>
      <AuthScreenLayout demoEmail={demoEmail} demoPassword={demoPassword}>
        <Stack
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: '100%' }}
          spacing={2}
        >
          {/* email */}
          {showNext ? (
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('Email Address')}
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              disabled={loading || showPassword}
              error={
                (!!formError && !!authError.message?.length) ||
                (!!formError && !!formError.email)
              }
              defaultValue={
                process.env.REACT_APP_IS_DEMO_SITE ? demoEmail : undefined
              }
              key={process.env.REACT_APP_IS_DEMO_SITE ? demoEmail : undefined}
            />
          ) : (
            <Stack direction="row" spacing={1} sx={{ pb: 1 }}>
              <Box>
                <IconButton
                  aria-label="back"
                  onClick={() => {
                    setShowNext(true);
                    setShowPassword(false);
                    setShowLoginWithMs(false);
                    setLoading(false);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Box>
              <Box sx={{ maxWidth: '100%' }}>
                <Typography
                  component="span"
                  style={{ overflowWrap: 'anywhere', maxWidth: '100%' }}
                >
                  {email}
                </Typography>
              </Box>
            </Stack>
          )}

          {/* password */}
          {showNext ? (
            <AuthLoginNext loading={loadingSsoOnlyDomains} />
          ) : showPassword ? (
            <AuthLoginPassword
              {...{ loading, authError, formError, demoPassword }}
            />
          ) : showLoginWithMs ? (
            <LoadingButton
              disableElevation
              variant="contained"
              href={getApiURL('/auth/sso/login/azuread-oauth2/')}
              onClick={() => {
                setLoading(true);
              }}
              loading={loading}
            >
              Login with Microsoft
            </LoadingButton>
          ) : null}

          <AuthLoginErrorMessage {...{ authError, formError }} />
        </Stack>
      </AuthScreenLayout>

      {/* SSO only domains subscription */}
      {!!dataSsoOnlyDomains && (
        <SubscribeToMoreComponent
          subscribeToMore={subscribeToSsoOnlyDomains}
          document={AUTH_SSO_ONLY_DOMAINS_SUBSCRIPTION}
        />
      )}

      {/* auth is demo site */}
      {!!dataIsDemoSiteQuery && (
        <SubscribeToMoreComponent
          subscribeToMore={subscribeToMoreAuthIsDemosSite}
          document={AUTH_IS_DEMO_SITE_SUBSCRIPTION}
        />
      )}
    </>
  );
}
