import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useAppContext } from '../../../../_lib/context/app-context';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { AppbarAvatarAlertsDemo } from './demo';
import { AppbarAvatarAlertsEmailsDisabled } from './emails-disabled';
import { AppbarAvatarAlertsUpdate } from './update';

export function AppbarAvatarAlerts() {
  const { dashboard } = useDashboardContext();
  const { waitingWorker } = useAppContext();

  if (
    !process.env.REACT_APP_IS_DEMO_SITE &&
    dashboard.enableEmailNotifications &&
    !waitingWorker
  )
    return null;

  return (
    <Box sx={{ width: '210px', minWidth: '100%' }}>
      <Stack sx={{ my: 0.5, mx: 1.5 }} spacing={1.5}>
        {/* demo */}
        <AppbarAvatarAlertsDemo />

        {/* emails disabled */}
        <AppbarAvatarAlertsEmailsDisabled />

        {/* update available */}
        <AppbarAvatarAlertsUpdate />
      </Stack>
    </Box>
  );
}
