import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import LoadingButton from '@mui/lab/LoadingButton';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../../../_lib/react-error';
import { logError } from '../../../../_lib/error';

import { TWO_FACTOR_DISABLE } from '../../../../_lib/graphql/mutations';
import { CONTEXT_USER_QUERY } from '../../../../_lib/graphql/queries';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';
import { TwoFactorSteps } from './two-factor-steps';
import { MyAccountSection } from '../../section';

export function TwoFactorSettings({
  isSsoOnlyAccount,
}: {
  isSsoOnlyAccount: boolean;
}) {
  const { t } = useTranslation();
  const { user, setSnackbarIsError, setSnackbarOpen } = useDashboardContext();

  const [openTwoFactorDialog, setOpenTwoFactorDialog] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [cancelLoading, setCancelLoading] = React.useState(false);

  const [disableTwoFactorMutation] = useMutation(TWO_FACTOR_DISABLE, {
    refetchQueries: [{ query: CONTEXT_USER_QUERY }],
  });

  // ---------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------------------------

  const handleClose = () => {
    if (!user?.isTwoFactorEnabled) {
      // disable two factor incase started but not completed
      setCancelLoading(true);

      disableTwoFactorMutation()
        .then(() => {
          setCancelLoading(false);
          setOpenTwoFactorDialog(false);
        })
        .catch((e) => {
          setCancelLoading(false);
          logError(e);
          setSnackbarIsError(true);
          setSnackbarOpen(true);
        });
    } else {
      setLoading(false);
      setOpenTwoFactorDialog(false);
    }
  };

  // ---------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <MyAccountSection
        icon={<LockOutlinedIcon />}
        title={t('Two Factor Authentication')}
        titleChip={
          user?.isTwoFactorEnabled ? (
            <Chip
              color="success"
              size="small"
              label={t('Enabled')}
              icon={<LockIcon />}
            />
          ) : (
            <Chip
              color="default"
              size="small"
              label={t('Disabled')}
              icon={<LockOpenIcon />}
            />
          )
        }
        description={t(
          'Enable two-factor authentication (2FA) to add an extra layer of security to your account.'
        )}
        action={
          <Button
            variant="contained"
            color={user?.isTwoFactorEnabled ? 'warning' : 'primary'}
            sx={{
              textTransform: 'none',
            }}
            onClick={() => setOpenTwoFactorDialog(true)}
            disabled={isSsoOnlyAccount}
          >
            {user?.isTwoFactorEnabled ? t('Disable') : t('Enable')} 2FA
          </Button>
        }
      />

      {/* --------------------------------------------------------------------------------------------------------- */}
      {/* dialog */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openTwoFactorDialog}
        sx={{ minHeight: '250px' }}
      >
        <DialogTitle
          onClose={handleClose}
          variant="h6"
          sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}
        >
          {user?.isTwoFactorEnabled
            ? t('Disable Two Factor Authentication')
            : t('Enable Two Factor Authentication')}
        </DialogTitle>

        {/* steps to enable or disable */}
        <DialogContent dividers>
          <Box>
            <ErrorBoundary FallbackComponent={ReactErrorComponent}>
              <TwoFactorSteps
                handleClose={() => setOpenTwoFactorDialog(false)}
                disabled={cancelLoading}
                loading={loading}
                setLoading={setLoading}
              />
            </ErrorBoundary>
          </Box>
        </DialogContent>

        {/* actions */}
        <DialogActions sx={{ py: 2, px: 2 }}>
          <LoadingButton
            variant="contained"
            onClick={handleClose}
            loading={cancelLoading}
            disabled={loading}
          >
            {t('Cancel')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
