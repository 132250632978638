import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';

export function AuthLoginErrorMessage({
  authError,
  formError,
}: {
  authError: any;
  formError: any;
}) {
  const { t } = useTranslation();
  if (!authError.message) return null;
  return (
    <Alert severity="error">
      {!!formError.email || !!formError.password ? (
        t(`Email and password cannot be empty.`)
      ) : Object.values(authError).some((a: any) => a) ? (
        <span dangerouslySetInnerHTML={{ __html: authError.message }} />
      ) : null}
    </Alert>
  );
}
