import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import useScrollTrigger from '@mui/material/useScrollTrigger';

import TbiDdLogo from '../../../assets/img/tbilogo/dashboard.jpg';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { DashboardAppBarAvatar } from './avatar';
import { DashboardAppBarDrawer } from './drawer';
import { NavbarAppBarSearch } from './search';
import { NavbarAppBarAdd } from './add';

interface HideOnScrollProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  // eslint-disable-next-line react/require-default-props
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export function DashboardAppBar(props: any) {
  const {
    logoSrc,
    title,
    areasFilter,
    showAreasFilter,
    launchDashboard,
    showLaunchDashboard,
  } = props;
  const { t } = useTranslation();

  const { dashboard, appBarLoading } = useDashboardContext();

  return (
    <>
      <HideOnScroll>
        <AppBar elevation={0}>
          <Toolbar>
            {/* drawer */}
            <DashboardAppBarDrawer {...props} />

            {/* logo + title */}
            <ButtonBase
              disableRipple
              sx={{
                '&:hover': {
                  color: 'inherit',
                  textDecoration: 'none',
                },
                minWidth: 0,
              }}
              component={RouterLink}
              to="/"
            >
              <img
                src={
                  (logoSrc?.includes('/static/images/tbilogo.jpg') &&
                  process.env.REACT_APP_IS_V3
                    ? TbiDdLogo
                    : logoSrc) || TbiDdLogo
                }
                alt="dashboard logo"
                height="35"
                style={{
                  marginRight: '10px',
                }}
                onError={(e: any) => {
                  if (e.target.src !== TbiDdLogo) {
                    e.target.src = TbiDdLogo;
                  }
                }}
              />
              <Typography
                variant="h6"
                component="div"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  cursor: 'pointer',
                }}
              >
                {title || t('Digital Delivery Dashboard')}
              </Typography>
            </ButtonBase>

            {/* filter */}
            {showAreasFilter && (
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  ml: 3,
                  py: 1,
                  width: '300px',
                  displayPrint: 'none',
                }}
              >
                {React.cloneElement(areasFilter, { isDark: true })}
              </Box>
            )}

            {/* launch button */}
            {showLaunchDashboard && (
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  ml: 3,
                }}
              >
                {React.cloneElement(launchDashboard, { isDark: true })}
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }} />

            {/* search and add */}
            {!showLaunchDashboard && !!dashboard.id && (
              <>
                <NavbarAppBarSearch />
                {/* add menu */}
                {dashboard.mode !== 'SUPRANATIONAL' && <NavbarAppBarAdd />}
              </>
            )}

            {/* avatar */}
            <DashboardAppBarAvatar />
          </Toolbar>
          {appBarLoading && <LinearProgress />}
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </>
  );
}
