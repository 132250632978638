import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AddchartIcon from '@mui/icons-material/Addchart';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { CreateDashboardDialog } from '../../../navbar/create-dashboard-dialog';

export function MyAccountPreferencesNoDashboards() {
  const { t } = useTranslation();
  const { user } = useDashboardContext();

  const [showCreateDashboardDialog, setShowCreateDashboardDialog] =
    React.useState(false);
  return (
    <Box>
      <Typography sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
        {t('No Dashboards found.')}
      </Typography>

      {/* if can create */}
      {user.canCreateDashboards && (
        <>
          <CreateDashboardDialog
            open={showCreateDashboardDialog}
            onClose={() => setShowCreateDashboardDialog(false)}
          />

          <Button
            startIcon={<AddchartIcon />}
            variant="contained"
            sx={{ mt: 2.5 }}
            onClick={() => setShowCreateDashboardDialog(true)}
          >
            {t('Create Dashboard')}{' '}
          </Button>
        </>
      )}
    </Box>
  );
}
