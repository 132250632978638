import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { SubscribeToMoreComponent } from '../../subscribe-to-more';

import { AUTH_SSO_ONLY_DOMAINS_QUERY } from '../../../_lib/graphql/queries';
import { AUTH_SSO_ONLY_DOMAINS_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

import { ChangePasswordDialog } from '../../../navbar/navbar-menu/change-password/dialog';

import { CalendarURL } from './calendar-url';
import { TwoFactorSettings } from './two-factor';

export function MyAccountDetails() {
  const { t } = useTranslation();
  const { dashboard, user } = useDashboardContext();

  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    React.useState(false);

  const {
    data: dataSsoOnlyDomains,
    loading: loadingSsoOnlyDomains,
    error: errorAuthSsoOnlyDomains,
    subscribeToMore: subscribeToSsoOnlyDomains,
  } = useQuery(AUTH_SSO_ONLY_DOMAINS_QUERY);

  const isSsoOnlyAccount = React.useMemo(() => {
    if (loadingSsoOnlyDomains || errorAuthSsoOnlyDomains) return false;
    return (
      dataSsoOnlyDomains?.authSsoOnlyDomains?.filter((d: any) =>
        user.email.endsWith(`@${d}`)
      ).length > 0
    );
  }, [
    dataSsoOnlyDomains,
    errorAuthSsoOnlyDomains,
    loadingSsoOnlyDomains,
    user.email,
  ]);

  return (
    <>
      {/* change password dialog */}
      <ChangePasswordDialog
        open={openChangePasswordDialog}
        setOpen={setOpenChangePasswordDialog}
      />

      <Card sx={{ mb: 6, px: 3, py: 4 }}>
        <Stack spacing={3}>
          {/* alert for SSO only account */}
          {isSsoOnlyAccount && (
            <Alert severity="info">
              {t('This is a Single sign-on (SSO) only account.')} <br />
              <a
                href="https://en.wikipedia.org/wiki/Single_sign-on"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Learn More')} →
              </a>
            </Alert>
          )}

          {/* email */}
          <TextField
            type="email"
            label={t('Email address')}
            value={user.email}
            disabled
            fullWidth
          />

          <Button
            variant="contained"
            onClick={() => setOpenChangePasswordDialog(true)}
            sx={{ textTransform: 'none' }}
            startIcon={<LockResetOutlinedIcon />}
            disabled={isSsoOnlyAccount}
          >
            {t('Change Password')}
          </Button>

          {/* 2 factor authentication */}
          <Divider />
          <TwoFactorSettings isSsoOnlyAccount={isSsoOnlyAccount} />

          {/* calendar url */}
          {dashboard.enableCalendarLink && [
            <Divider key="my-account-calendar-divider" />,
            <CalendarURL key="my-account-calendar" />,
          ]}
        </Stack>
      </Card>

      {/* SSO only domains subscription */}
      <SubscribeToMoreComponent
        subscribeToMore={subscribeToSsoOnlyDomains}
        document={AUTH_SSO_ONLY_DOMAINS_SUBSCRIPTION}
      />
    </>
  );
}
